import { Container, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { BackButton, CircularProgress, Spacer, Typography, Avatar, Section, Icon, Alert } from '../components/library'
import { ImpactContext } from '../components/Layout'
import theme from '../theme'
import hexToRgbA from '../utils/hexToRgbA'
import Goal from '../components/Goal'

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'left',
    },
    header: {
        padding: theme.spacing(0, 0, 1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        minWidth: 360,
        '& > * ': {
            marginRight: '0.5em'
        },
        '& .MuiTypography-caption': {
            marginRight: 0,
            width: '100%',
        }
    },
    avatar: {
        width: 40,
        height: 40,
        [theme.breakpoints.down("md")]: {
            width: 30,
            height: 30
        },
        [theme.breakpoints.down("xs")]: {
            width: 25,
            height: 25
        }
    },
}))

function toDo() {
    const { profiles, userData, previousWeek, isPastWeek } = useContext(ImpactContext)
    const classes = useStyles();
    const [backTo, setBackTo] = React.useState(null)

    React.useEffect(() => {
        setBackTo(isPastWeek && previousWeek.todo_left === 1 ? '/' : '/weekly-to-do')
    }, [])

    if(!profiles || !userData || !previousWeek) return <CircularProgress />

    const spiro_card = previousWeek.spiro_done ? (
        <Section 
            title="Spirometry Test" 
            icon={<Icon name="spirometer" bg={theme.palette.pink.lighter} fg={theme.palette.pink.main} />} 
            bg="glass"
            
        >
            <Typography variant="body1" align="left">
                {`Take the spirometry test with your spirometer for week ${previousWeek.number + 1}.`}
            </Typography>
        </Section>
    ) : (
        <Section 
            title="Spirometry Test" 
            icon={<Icon name="spirometer" bg={theme.palette.pink.lighter} fg={theme.palette.pink.main} />} 
            actionLabel="Test now"
            actionTo="/spirometry-test-form"
            backTo="/weekly-to-do"
            footer={<Typography variant="caption">You can earn 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
        >
            <Typography variant="body1" align="left">
                {`Take the spirometry test with your spirometer for week ${previousWeek.number + 1}.`}
            </Typography>
        </Section>

    )

    const cact_card = previousWeek.cact_done ? (
        <Section 
            title="C-ACT" 
            icon={<Icon name="spirometer" bg={theme.palette.blue.lighter} fg={theme.palette.blue.main} />} 
            bg="glass"
        >
            <Typography variant="body1" align="left">
                {`Take the Childhood Asthma control Test questionnaire for week ${previousWeek.number + 1}.`}
            </Typography>
        </Section>

    ) : (
        <Section 
            title="C-ACT" 
            icon={<Icon name="spirometer" bg={theme.palette.blue.lighter} fg={theme.palette.blue.main} />} 
            actionLabel="Test now"
            actionTo="/c-act-form"
            backTo="/weekly-to-do"
            footer={<Typography variant="caption">You can earn up to 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
        >
            <Typography variant="body1" align="left">
                {`Take the Childhood Asthma control Test questionnaire for week ${previousWeek.number + 1}.`}
            </Typography>
        </Section>
    )

    return (
        <Container maxWidth="md">
            <Spacer /> 
            <BackButton to="/">Home</BackButton>
            <Spacer amount="1.5" />
            <div className={classes.header}>
                <Typography variant="h1" shade="dark" className={classes.title}>{`Week ${previousWeek.number + 1} To Do`}</Typography>
                {profiles !== null && (
                    [profiles.child, profiles.parent].map(profile => <Avatar tooltip className={classes.avatar} key={profile.id} profile={profile}  />)
                )}
            </div>
            <Typography variant="caption" shade="dark"  color={isPastWeek ? theme.palette.orange.main : 'gray'}>{previousWeek.label}</Typography>
            
            {(!previousWeek.spiro_done || !previousWeek.cact_done || previousWeek.goals_are_all_checked_in || isPastWeek) && <Spacer amount="4" />}

            {isPastWeek && (
                <>
                    <Alert
                        title={`Outstanding tasks from week ${previousWeek.number + 1}`}
                        icon={<Icon name="attention" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
                        alignMiddle
                        color="orange"
                    >
                        <span>Please complete these tasks before moving on to the current week</span>
                    </Alert>
                    <Spacer amount="4" />
                </>
            )}

            {previousWeek.todo_done && (
                <>
                    <Alert
                        title="All tasks completed for this week"
                        icon={<Icon name="ok" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
                        alignMiddle
                        color="secondary"
                    />
                </>
            )}

            {!previousWeek.spiro_done && (spiro_card)}
            {!previousWeek.cact_done && (cact_card)}

            {previousWeek.goals_not_checked_in.length > 0 && (
                <>
                    <Spacer amount="2" />
                    {previousWeek.goals_not_checked_in.map((uuid) => <Goal goal={userData[uuid]} uuid={uuid}  key={uuid} backTo={backTo} action />)}
                </>
            )}

            {(previousWeek.goals_checked_in.length > 0 || previousWeek.cact_done || previousWeek.spiro_done) && !previousWeek.todo_done && (
                <>
                    <Spacer amount="4" />
                    <Typography variant="subtitle1" shade="dark" className={classes.subtitle}>Done:</Typography>
                    <Spacer amount="1" />
                </>
            )}

            {previousWeek.todo_done && <Spacer amount="4" />}

            {previousWeek.spiro_done && (spiro_card)}
            {previousWeek.cact_done && (cact_card)}

            {previousWeek.goals_checked_in.length > 0 && (
                <>
                    {previousWeek.goals_checked_in.map((uuid) => <Goal goal={userData[uuid]} uuid={uuid} key={uuid} complete />)}
                </>
            )}

            <Spacer amount="7" />
        </Container>
    )
}

export default toDo
